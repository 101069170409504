import { Component, OnInit } from '@angular/core';
import {Passation} from '../passation';
import {PassationService} from '../passation.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css'],
  host: {'class': 'ui-body-content-component d-flex flex-grow-1 flex-column'}
})
export class ItemsComponent implements OnInit {

  constructor(private passationService : PassationService) { }

  passation ?:Passation;
  itemNumbers ?:Number[];
  ngOnInit(): void {
        this.passation = this.passationService.getPassation();
  }

  onChange():void{
    for (let i = 0; i< this.passation.items.length-1;i++){
      if (this.passation.items[i].name === "" && this.passation.items[i+1].name!== ""){
        this.passation.items[i].name = this.passation.items[i+1].name;
        this.passation.items[i+1].name="";
      }
    }
    this.savePassation();
  }

  savePassation():void{
    this.passationService.savePassation();
  }
}
