import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {ContenteditableModule} from '@ng-stack/contenteditable';

import { HttpClientModule,HTTP_INTERCEPTORS  } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRootingModule } from './app-rooting.module';
import { PassationComponent } from './passation/passation.component';
import { InformationComponent } from './information/information.component';
import { ItemsComponent } from './items/items.component';
import { ItemsWeightComponent } from './items-weight/items-weight.component';
import { ItemsNoteComponent } from './items-note/items-note.component';
import { ItemsPillarComponent } from './items-pillar/items-pillar.component';
import { ResultsComponent } from './results/results.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminComponent } from './admin/admin.component';
import { ErrorInterceptor} from './error.interceptor';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UserTextPipe } from './user-text.pipe';
import { EnabledPipe } from './enabled.pipe';

@NgModule({
  declarations: [
    AppComponent,
    PassationComponent,
    InformationComponent,
    ItemsComponent,
    ItemsWeightComponent,
    ItemsNoteComponent,
    ItemsPillarComponent,
    ResultsComponent,
    AdminComponent,
    HomeComponent,
    LoginComponent,
    UserTextPipe,
    EnabledPipe
  ],
  imports: [
    BrowserModule,
    AppRootingModule,
    BrowserModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    HttpClientModule,
    ContenteditableModule,

  ],
  providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      ],
  bootstrap: [AppComponent]
})
export class AppModule { }
