<div class="d-flex flex-grow-1 align-items-center justify-content-center">
  <div class="" *ngIf="passation">
    <div  class="container passation-main-div">
      <h2 class="row" class="passation-title-div">Ces éléments n'ont peut-être pas tous la même importance pour vous. Indiquez l'importance de chaque élément en %</h2>
      <div  *ngFor="let item of passation.items ; let i = index" >
        <div *ngIf="item.name!==''" class = "row d-flex"  >
          <div class ="d-flex justify-content-center col-12 passation-item-div border-item-color-{{i+1}}">
            <div class="item-number-div item-color-{{i+1}} col-2">{{i+1}}</div>
            <div class="flex-grow-1 passation-item-name-div" >{{item.name}} </div>
            <div class="d-flex passation-range-div-wrapper align-items-center">
              <input (change)="onWeightChange()" class="passation-range-div form-control" [(ngModel)]="item.weight" type="number" min="0" max="100" >
              <div class="passation-item-weight-text">% </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div [ngClass]="{'input-error':totalWeight!=100}" >
          <strong>total :  {{ totalWeight}} %</strong>
          <span style="float:right;" *ngIf="totalWeight>100" >Il reste {{ totalWeight-100}}% à retrancher </span>
          <span style="float:right;" *ngIf="totalWeight<100" >Il reste {{ 100-totalWeight}}% à répartir</span>
        </div>
      </div>

    </div>
  </div>

</div>
<div class="passation-button-div d-flex justify-content-center">
  <button class="btn btn-primary" routerLink="/items">retour</button>
  <button [disabled] = "totalWeight !==100" class="btn btn-primary" routerLink="/items-note">suivant</button>
</div>

