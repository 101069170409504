import { Injectable } from '@angular/core';
import {Passation,Tag, Item} from './passation';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import  {serverURL} from "./config";

@Injectable({
  providedIn: 'root'
})
export class PassationService {

  private currentPassation ?: Passation;
  private apiUrl :string = serverURL+"/api/passation";
  private httpOptions = {
    withCredentials:true,
    headers: new HttpHeaders(
    {
      'Content-Type':  'text/plain',
    })};
  constructor(  private http: HttpClient){

  }


  public hasPassation():boolean{
    if (this.currentPassation == null){
      var savedPassation:string =  sessionStorage.getItem("passation");
      if(savedPassation == null){
        return false;
      }
      this.currentPassation = JSON.parse(savedPassation);
    }
    return true;
  }

  public startNewPassation(): void{
      this.currentPassation = new Passation();
      this.currentPassation.items = new Array(5);
      for (let i =0;i<5;i++){
        this.currentPassation.items[i]= new Item();
      }
  }

  public getPassation() : Passation{
    return this.currentPassation;
  }
  public clearPassation():void{
      this.currentPassation = null;
      sessionStorage.removeItem('passation');
  }

  public savePassation() : void{
    sessionStorage.setItem('passation',JSON.stringify(this.currentPassation));
  }
  public doCodeExist(code:string) : Observable<boolean>{
    return this.http.get<boolean>(this.apiUrl+"/doCodeExist/"+code,{withCredentials: true});
  }

  public sendPassation(): Observable<object>{
    return this.http.post(this.apiUrl, this.currentPassation,{withCredentials: true});
  }

  public getPreviousPassations(): Observable<any>{
    return this.http.get(this.apiUrl+"/getPreviousPassations/"+this.currentPassation.code,{withCredentials: true });
  }

  public getTags() :Observable<TagView>{
    return this.http.get<TagView>(this.apiUrl+"/tags",{withCredentials: true});
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }

  }
}

export class TagView{
  tags: Tag[];
}
