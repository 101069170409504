<form (ngSubmit)="onSubmit()" ngNativeValidate method="POST" class="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
  <div class="d-flex flex-column">
    <div *ngIf="passation" class="container form-passation passation-main-div">
      <div class="form-group d-flex flex-md-row flex-column">
        <label for="passationCode" class="ui-label-margin">Identifiant répondant</label>
        <div class ="d-flex flex-column">
          <input (change)="savePassation()" id="passationCode" [(ngModel)] = "passation.code" class=""
                  name ="code" required minlength="3" maxlength="20" #code="ngModel" (focusout)="onCodeFocusOut()"/>
          <span class="information-hint" *ngIf="doCodeExist==true">Il existe des passations précédentes pour ce code</span>
          <span class="information-hint"  *ngIf="doCodeExist==false">C'est la première passation pour ce code</span>
        </div>
        <div *ngIf="code.invalid && (code.dirty || code.touched||hasTriedInvalidForm)"
             class="alert alert-danger">
          <div *ngIf="code.errors?.required">
            Le code répondant est nécessaire.
          </div>
          <div *ngIf="code.errors?.minlength">
            le code doit faire à minima 3 caractères.
          </div>
        </div>
        <div class="ui-input-hint">Attention, l'identifiant choisi doit être le même pour des passations répétées.</div>
      </div>
      <div class="form-row d-flex flex-md-row flex-column">
        <div class="form-group col-md-6">
          <label for="passationGender" class="ui-label-margin">Genre</label>
            <select (change)="savePassation()" id="passationGender" [(ngModel)] = "passation.gender" name ="gender" class="" #gender="ngModel">
              <option  [value]="0">Femme</option>
              <option  [value]="1">Homme</option>
              <option  [value]="2">NSP</option>
            </select>
        </div>
        <div class="form-group col-md-6">
          <label for="passationNaissance" class="ui-label-margin">Année de naissance</label>
          <input (change)="savePassation()" min="1900" max="2021" maxlength="4" type="Number" id="passationNaissance"
                 [(ngModel)] = "passation.naissance" name = "naissance" class="" #naissance="ngModel" required>
          <div *ngIf="naissance.invalid && (naissance.dirty || naissance.touched||hasTriedInvalidForm)"
               class="alert alert-danger">
            <div *ngIf="naissance.errors?.required">
              L'année de naissance du patient est nécessaire.
            </div>
            <div *ngIf="naissance.errors?.pattern">
              La date est invalide.
            </div>
          </div>
        </div>

      </div>

      <div class="form-row d-flex flex-md-row flex-column">
        <div class="form-group col-md-6">
          <label class="ui-label-margin">Date passation</label>
          <input (change)="savePassation()" type="date" [(ngModel)] = "passation.date" name="date" #date="ngModel" required
                 [ngModel]="passation.date | date:'yyyy-MM-dd'"
                 (ngModelChange)="passation.date = $event"
          />
          <div *ngIf="date.invalid && (date.dirty || date.touched||hasTriedInvalidForm)"
               class="alert alert-danger">
            <div *ngIf="date.errors?.required">
              La date de passation est requise.
            </div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label class="ui-label-margin">Code postal (passation)</label>
          <input (change)="savePassation()" type="text" [(ngModel)] = "passation.codePostal"
                 name = "codePostal" pattern="[0-9]*" required size="8" #codePostal="ngModel"/>

          <div *ngIf="codePostal.invalid && (codePostal.dirty || codePostal.touched||hasTriedInvalidForm)"
               class="alert alert-danger">
            <div *ngIf="codePostal.errors?.required">
              Un code postal est nécessaire.
            </div>
            <div *ngIf="codePostal.errors?.pattern">
             Code postal invalide.
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <label class="" for="passationTags">Tags (service, groupe, activité ou lieu...)</label>
        <!--Using items input-->
        <ng-select (change)="savePassation()" class="ui-ng-select" id = "passationTags" name = "passationTags"
          [items]="tags"
          bindLabel="name"

          [(ngModel)]="passation.tags"
          [addTag]="true"
          [multiple] ="true">
        </ng-select>
      </div>


      <div [hidden]="errorMessage =='' " class="information-retour-zone-div">
        <span class="information-retour-zone-text">{{errorMessage}}</span>
      </div>
    </div>
  </div>
  <div class="passation-button-div d-flex justify-content-center">
    <button class="btn btn-primary" routerLink="/">retour</button>
    <input  type="submit" class ="btn btn-primary"   value="Suivant">
  </div>
</form>
