<div class=" d-flex flex-grow-1 align-items-center">
  <div class="container" *ngIf="passation">
    <h2 class="" class="passation-title-div">Quels sont les 5 éléments les plus importants pour vous, pour avoir une bonne et belle vie ?</h2>  
    <div class="">
      <div  class="col-12  passation-main-div ">
          <div class="d-flex ui-item-list-item justify-content-center ui-passation-item-line" *ngFor="let item of passation.items ; let i = index">
            <span class="col-2 item-number-div item-color-{{i+1}} ">{{i+1}}</span>
            <input (change)="onChange()" class="col-10 item-input"
                  [class.item-input-filled]="item.name!==''"
                  type ="text" [(ngModel)] = "item.name"
                  [disabled]="i>0 && passation.items[i-1].name===''"
                  placeholder="Elément {{i+1}}">
        </div>
      </div>
    </div>
    <div style="text-align:center;" class="">Si vous n'en trouvez pas 5, ce n'est pas grave vous pouvez passer à l'étape suivante.</div>
  </div>
</div>
<div class = "passation-button-div d-flex justify-content-center" >
  <button class="btn btn-primary" routerLink="/information">retour</button>
  <button class="btn btn-primary" [disabled]="passation.items[0].name === ''" routerLink="/items-weight">suivant</button>
</div>
