<div class="result-wrapper">
  <!-- header rapport -->
  <div class="d-flex justify-content-between align-items-center result-header">
    <div class="d-none d-md-block d-print"><h2>Fiche synthèse</h2></div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="result-title-div">Identifiant:<strong>{{passation.code}}</strong></div>
      <div class="result-title-div">Naissance:<strong>{{passation.naissance}}</strong></div>
      <div class="result-title-div">Genre:<strong>{{ toGenderString(passation.gender)}}</strong></div>
    </div>
    <div class="d-none d-md-block d-print">
      <img  src="assets/logo_atev.png" class = "result-logo-atev-img">
    </div>
  </div>


  <div class="result-passations-wrapper">
    <!-- rendu passations -->
    <div class="d-flex flex-column result-passation-width " *ngIf="previousPassation != null" [ngClass] ="{ 'width-comparaison-active':previousPassation != null}">
      <div  class="result-passation result-padding">
        <div class="result-passation-resume">
          <div>Date: <strong>{{previousPassation.date| date:"dd/MM/yyyy"}}</strong></div>
          <div>Tags associés : <span *ngFor ="let tag of previousPassation.tags">{{tag.name}} </span></div>
          <div>Operateur/trice : {{previousPassation.passateurName}} <span *ngIf="previousPassation.organizationName !=''">   {{previousPassation.organizationName}}  </span> </div><!-- NG IF entité sur span -->
        </div>
        <div class="result-passation-score d-flex justify-content-center align-items-center result-padding">
          <div>Score global qualité de vie: </div>
          <div class="result-score-number">{{previousPassation.totalScore.toFixed(1)}}/100</div>
        </div>
        <div class="result-passation-items-list result-padding">
          <div class="result-passation-titre-section">Eléments de la qualité de la vie</div>
          <div class="d-flex align-items-center justify-content-around result-item" style="color:rgb(185, 185, 185);">
            <div class="flex-grow-1">Eléments</div>
            <div>Part</div>
            <div>Note</div>
          </div>
          <div class="result-passation-items-item" *ngFor="let item of previousPassation.items">
            <div class="d-flex align-items-center justify-content-around result-item" *ngIf="item.name !== ''">
              <div class="result-pillar-mini-div">
                <img  src="../../assets/pillar-{{item.pillar}}.png">
              </div>
              <div class="flex-grow-1">{{item.name}}</div>
              <div>  {{item.weight}}%</div>
              <div><b>{{item.note}}</b><em>/10</em></div>
            </div>
          </div>
        </div>
        <div class="result-passation-graph result-padding" id ="previous-passation-pies">
          <div class="result-passation-titre-section">Importance des éléments</div>
          <div class="result-pie pillarProportion"></div >
          <div class="result-passation-titre-section">Scores de qualité de vie</div>
          <div class="result-pie pillarScoreProportion"></div >
        </div>
      </div>
    </div>

    <div class="d-flex flex-column result-passation-width result-passation-comparaison" [ngClass] ="{'result-passation-active':activeTab==1,'width-comparaison-active':previousPassation != null }" >
      <div class="result-passation result-padding"> <!-- passation de base, toujours présente, l'autre vient par dessus-->
        <div class="result-passation-resume">
          <div>Date: <strong>{{passation.date| date:"dd/MM/yyyy"}}</strong></div>
          <div>Tags associés : <span *ngFor ="let tag of passation.tags">{{tag.name}} </span></div>
            <div>Operateur/trice :  {{passation.passateurName}} <span *ngIf="passation.organizationName !='' "> {{passation.organizationName}}</span></div>
        </div>
        <div class="result-passation-score d-flex justify-content-center align-items-center result-padding">
          <div>Score global qualité de vie: </div>
          <div class="result-score-number">{{passation.totalScore.toFixed(1)}}/100</div>
        </div>
        <div class="result-passation-items-list result-padding">
          <div class="result-passation-titre-section">Eléments de la qualité de la vie</div>
            <div class="d-flex align-items-center justify-content-around result-item" style="color:rgb(185, 185, 185);">
              <div class="flex-grow-1">Eléments</div>
              <div>Part</div>
              <div>Note</div>
            </div>
            <div class="result-passation-items-item" *ngFor="let item of passation.items">
              <div class="d-flex align-items-center justify-content-around result-item" *ngIf="item.name !== ''">
                <div class="result-pillar-mini-div">
                  <img  src="../../assets/pillar-{{item.pillar}}.png">
                </div>
                <div class="flex-grow-1">{{item.name}}</div>
                <div>  {{item.weight}}%</div>
                <div><b>{{item.note}}</b><em>/10</em></div>
              </div>
            </div>
        </div>

        <div class="result-passation-graph " id ="current-passation-pies">
          <div class="result-passation-titre-section">Importance des éléments</div>
          <div class="result-pie pillarProportion"></div >
          <div class="result-passation-titre-section">Scores de qualité de vie</div>
          <div class="result-pie pillarScoreProportion"></div >
        </div>
      </div>
    </div>

    </div>
  <div class="d-flex flex-wrap align-items-center justify-content-center">
    <div *ngFor="let pillarBoolean of isPillarPresent;let index = index">
      <div  *ngIf="pillarBoolean ==true" class="graph-caption-item graph-caption-pillar-{{index}}">
        {{pillarEnum[index]}}
      </div>
    </div>
  </div>
  <div *ngIf="previousPassation != null" class="d-flex flex-column align-items-center justify-content-center  result-comparaison">
      <h3>Evolution des piliers</h3>
    <div class="d-flex flex-column flex-md-row result-comparaison-items">
      <div  *ngFor="let evolution of pillarEvolution;let index = index"   >
        <div *ngIf="evolution[0] !='0' || evolution[1] !='0' " class="d-flex align-items-center justify-content-center" >
          <div class="result-pillar-mini-div">
            <img  src="../../assets/pillar-{{index}}.png">
          </div>
          <div class="result-comparaison-text">
            de {{evolution[0].toFixed(1)}} à {{evolution[1].toFixed(1)}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="result-passation-details d-flex justify-content-around">
    <div class="result-passation-details-commentaire col-6">
      <div>Commentaire: </div>
      <form >
        <div  [(ngModel)] ="passation.comment" name="commentInput" class="form-control commentaire-expandable" role="textbox" contenteditable></div>
      </form>
      <!-- <textArea class="form-control" rows="4" cols="" ></textArea> -->
    </div>

    <!--  <form>
        <textArea ngDefaultControl #comment='ngModel'  [(ngModel)] ="passation.comment"  name="comment" class="form-control" rows="4" cols="" ></textArea>
      </form>
     </div>-->

    <div class="result-passation-details-nom col-4">
      <div>Nom: <em>(donnée non sauvegardée)</em></div>
      <input  class="form-control"  type ="text">
    </div>
  </div>
  <!-- actions -->
  <div class="passation-comparaison hidePrint">
    <button *ngIf="previousPassation != null" (click)="activeTab=0" class="btn btn-secondary passation-comparaison-picker" [ngClass] ="{'active':activeTab==0}">Précédente</button>
    <button *ngIf="previousPassation != null" (click)="activeTab=1" class="btn btn-secondary passation-comparaison-picker" [ngClass] ="{'active':activeTab==1}">Aujourd'hui</button>

    <button [disabled]="hasPreviousPassation== false" *ngIf="previousPassation == null" (click)="openModal(modal)" class="btn btn-primary">Comparer</button>
    <button *ngIf="previousPassation != null" (click)="cancelPassationComparaison()" class="btn btn-primary">Annuler comparaison</button><br />
    <!-- switch/tabs pour version mobile ICI pour afficher l'une ou l'autre passation-->
  </div>
  <div class ="passation-button-div passation-actions d-flex flex-column flex-md-row flex-wrap justify-content-center align-items-center hidePrint">
      <button  routerLink="/items-pillar" class="btn btn-primary">
        Retour
      </button>
      <button (click)="openModal(confirmModal)" class="btn btn-primary">
        Enregistrer et terminer
      </button >
      <button (click)="onPrintClicked()" class="btn btn-primary">
        Imprimer
      </button >
  </div>
</div>

<ng-template #modal let-modal>
  <div >
    <div  *ngIf="previousPassationList != null" >
    <div class="modal-header">
      passations précédentes pour {{passation.code}}
    </div>
    <div class="modal-body">
      <select [(ngModel)]="previousPassationChoice">
        <option hidden [ngValue] ="null">Choisir une passation précédente</option>
        <option [ngValue]="passation"  *ngFor ="let passation of previousPassationList"  > {{ passation.date.split('T')[0]|date :"dd/MM/yyyy"}}
          <span *ngFor="let tag of passation.tags">{{tag.name}} </span>
        </option>
      </select>
    </div>
    <div class="modal-footer" >
      <button class="btn btn-primary" (click)="modal.close()"> Annuler </button>
      <button class="btn btn-primary" [disabled]="previousPassationChoice == null" (click)="onValidatePassationChoice(modal,previousPassationChoice)"> Choisir </button>
    </div>
   </div>
  </div>
</ng-template>


<ng-template #confirmModal let-modal>
  <div >
    <div  *ngIf="previousPassationList != null" >
      <div class="modal-header">
        Confirmation
      </div>
      <div class="modal-body">
        <div>
          Valider et envoyer cette passation ? Il ne sera plus possible d'y revenir plus tard.
          Pensez donc à imprimer auparavant !
        </div>
        <div class = "result-send-error-text">
          {{sendError}}
        </div>
      </div>
      <div class="modal-footer" >
        <button class="btn btn-primary" (click)="modal.close()"> Retour </button>
        <button class="btn btn-primary" (click)="onValidateSave(modal)">Valider et Terminer</button>
      </div>

    </div>
  </div>
</ng-template>
