import { Component, OnInit } from '@angular/core';
import {Passation,Item} from '../passation';
import {PassationService} from '../passation.service';

@Component({
  selector: 'app-items-weight',
  templateUrl: './items-weight.component.html',
  styleUrls: ['./items-weight.component.css'],
  host: {'class': 'ui-body-content-component d-flex flex-grow-1 flex-column'}
})
export class ItemsWeightComponent implements OnInit {

  constructor(private passationService : PassationService) { }

  passation:Passation;
  totalWeight : number;
  ngOnInit(): void {
        this.passation = this.passationService.getPassation();

        if(this.passation.items[0].weight== -1){
          this.balanceWeight();
        }
        this.computeWeightSum();
  }

  balanceWeight():void{
    let iterator:number =0;
    let validItemCount = 0;
    while(iterator< this.passation.items.length && this.passation.items[iterator].name != "" ){
      validItemCount++;
      iterator++;
    }
    for (iterator =0;iterator < validItemCount; iterator++){
      this.passation.items[iterator].weight = Math.round( 100/validItemCount);
    }
    for(;iterator < this.passation.items.length ; iterator++){
      this.passation.items[iterator].weight = 0;
    }
  }

  /*onWeightChange(id,event){
    let diff:number= event -this.passation.items[id].weight;
    if (diff != 0){
        this.passation.items[id].weight = event;
    }
    let ItemsToBalance:Item[] = [];
    let maxModification :number = 0;
    for (let i =id+1;i< this.passation.items.length;i++)
    {
       if(this.passation.items[i].name !=""){
          maxModification += this.passation.items[i].weight;
          ItemsToBalance.push(this.passation.items[i]);
       }
      diff = Math.min(diff,maxModification);
    }
    let delta:number = Math.sign(diff);
    let iterator:number =0;
    while (diff !=0 && ItemsToBalance.length >0){
      let currentItem = ItemsToBalance[iterator % ItemsToBalance.length];
      console.log(currentItem);
      if (currentItem.weight + delta > 0 && currentItem.weight + delta <100 ){
           currentItem.weight -= delta;
           diff -=delta;
      }
      else{
        const toRemove = ItemsToBalance.indexOf(currentItem, 0);
           ItemsToBalance= ItemsToBalance.splice(toRemove, 1);
      }
      iterator++;
    }
    this.passation.items[id].weight = event;
  }*/


  computeWeightSum():void{
     let sum = 0;
     this.passation.items.forEach(item=>{
        if (item.name !="")
          sum+= item.weight;
     });
     this.totalWeight = sum;
  }

  onWeightChange():void{
    this.computeWeightSum();
    this.savePassation();

  }

  savePassation():void{
    this.passationService.savePassation();
  }
}
