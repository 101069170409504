import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import  {serverURL} from "./config";
import {UserDetails,UserRequest} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class AdminService {



  constructor(private http: HttpClient) {

   }

   public getUsers():Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/admin/user/getAll",{withCredentials: true });
   }

    public getOrganizations():Observable<Object>{
       return this.http.get<Object>(serverURL+"/api/superAdmin/organization/getAll",{withCredentials: true });
    }
    public createOrganization(name:String):Observable<Organization>{
      return this.http.put<Organization>(serverURL+"/api/superAdmin/organization",{'name':name},{withCredentials: true });
    }

    public createUser(newUser:UserRequest):Observable<UserDetails>{
      return this.http.put<UserDetails>(serverURL+"/api/admin/user",newUser,{withCredentials: true });
    }
    public updateUser(user:UserRequest):Observable<Object>{
      return this.http.post<UserDetails>(serverURL+"/api/admin/user",user,{withCredentials: true });
    }

    public updateUserPassword(userId:Number,pwd:String){
     return this.http.get<object>(serverURL+"/api/admin/user/pwd/"+userId+"/"+pwd,{withCredentials: true });
    }

   /* public deleteUser(userId:Number):Observable<Object>{
      return this.http.delete<Object>(serverURL+"/api/admin/user/"+userId,{withCredentials: true });
    }*/

    public updateOrganization(id:Number,name:String):Observable<Organization>{
      return this.http.post<Organization>(serverURL+"/api/superAdmin/organization",{'id':id,'name':name},{withCredentials: true });
    }
    /*public deleteOrganization(id:Number):Observable<Object>{
      return this.http.delete<Object>(serverURL+"/api/superAdmin/organization/"+id,{withCredentials: true });
    }*/

    public setEnabledOrganization(id:Number,enabled:Boolean):Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/superAdmin/organization/"+id+"/setEnabled/"+enabled,{withCredentials: true });
    }

    public setEnabledUser(id:Number,enabled:Boolean):Observable<Object>{
      return this.http.get<Object>(serverURL+"/api/admin/user/"+id+"/setEnabled/"+enabled,{withCredentials: true });
    }

}

export class Organization{
  id: Number;
  name: String;
}
