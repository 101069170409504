import { Component, OnInit } from '@angular/core';
import { PassationService} from '../passation.service';
import { Passation,Tag,GenderEnum} from  '../passation';
import {Router} from '@angular/router';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css'],
  host: {'class': 'ui-body-content-component d-flex flex-grow-1 flex-column'}
})
export class InformationComponent implements OnInit {

  passation ?: Passation;
  errorMessage : string ='';
  tags ?: Tag[];
  GenderEnum = GenderEnum;
  hasTriedInvalidForm :boolean = false;
  doCodeExist :boolean = null;

  constructor(private router : Router,private passationService : PassationService) { }

  ngOnInit(): void {
      this.passation = this.passationService.getPassation();
      this.passationService.getTags().subscribe(tagView => this.tags = tagView.tags);
      console.log(this.passation);
  }

  savePassation():void{
    this.passationService.savePassation();
  }

  validateNextStep():boolean{

    return this.passation.code !== ""
        && this.passation.date !==  null
        && this.passation.codePostal !==""
        && this.passation.naissance !== null;
  }

  onCodeFocusOut(){
    this.passationService.doCodeExist(this.passation.code).subscribe(anwser=> this.doCodeExist = anwser);
  }
  onSubmit():void{
    if(this.validateNextStep()){
        this.router.navigateByUrl('/items');
    }
    else{
      this.hasTriedInvalidForm = true;
    }
  }
}
