import { Component, OnInit } from '@angular/core';
import {Passation} from '../passation';
import {PassationService} from '../passation.service';

@Component({
  selector: 'app-items-note',
  templateUrl: './items-note.component.html',
  styleUrls: ['./items-note.component.css'],
  host: {'class': 'ui-body-content-component d-flex flex-grow-1 flex-column'}
})
export class ItemsNoteComponent implements OnInit {

  constructor(private passationService : PassationService) { }

  passation:Passation;
  notations:Number[] = [10,9,8,7,6,5,4,3,2,1,0];

  ngOnInit(): void {
        this.passation = this.passationService.getPassation();
  }

  validateStep():Boolean{
    for (let itemId in this.passation.items){
      if (this.passation.items[itemId].name !== "" && this.passation.items[itemId].note == -1)

        return false;
    }
    return true;
  }

  savePassation():void{
    this.passationService.savePassation();
  }
}
