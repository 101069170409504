<div class="d-flex align-items-center header-wrapper hidePrint">
  <div class="col-1"></div>
  <div><img src="../../assets/logo_atev.png" class="header-logo" /></div>
  <div class="flex-grow-1"></div>
  <div><button>se déconnecter</button></div>
  <div class="col-1"></div>
</div>
<router-outlet > </router-outlet>
<div class="d-flex justify-content-center align-items-center flex-column footer-wrapper  hidePrint">
  <div>Une réalisation du Keepic'Lab à Poitiers (86).</div>
  <div>Rendez-nous visite sur &nbsp; <a class="ui-login-link" href="https://www.keepic.net" target="_new">www.keepic.net</a> !</div>
</div>
