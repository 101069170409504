import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enabledOnly'
})
export class EnabledPipe implements PipeTransform {

  transform(value: any[], showEnabledOnly:Boolean): unknown {
     if (!showEnabledOnly)
        return value;
     else
        return value.filter(elt=> elt.enabled)

  }

}
