

<div class="d-flex align-items-center header-wrapper hidePrint">
  <div class="col-1"></div>
  <div><img src="../../assets/logo_atev.png" class="header-logo" /></div>
  <div class="d-flex flex-grow-1 justify-content-end align-items-center">
    <div class="d-none d-md-flex header-item">{{username}}</div>
    <button *ngIf=" isAdmin()" class="  btn btn-primary btn-deconnexion header-item" (click)="goToAdmin()">Admin</button>
    <button (click)="logout()" class="  btn btn-primary btn-deconnexion header-item">se déconnecter</button>
  </div>
  <div class="col-1"></div>
</div>

<div class="d-flex flex-column justify-content-around align-items-center flex-grow-1 hidePrint" *ngIf="isOnRoot()==true" >
  <div class="d-flex flex-column justify-content-center align-items-center ui-login-header">
    <div class=""><img class="logo-atev-big" src="../../assets/logo_atev.png" /></div>
    <div class="d-flex align-items-center">
      <div style="text-align:right;"><img class="logo-qualiviscope" src="../../assets/logo_qualiviscope.png" /></div>
      <div style="text-align: left;">
        <div style="padding-bottom:1em;">Bienvenue sur l'application Qualiviscope de l'Atelier de l'évaluation</div>
        <div style="font-size:.9em;">plus d'informations sur <a href="https://atelierdelevaluation.fr/" class="link" target="_new">https://atelierdelevaluation.fr</a></div>
      </div>
    </div>
  </div>
  <div class="d-flex passation-main-div justify-content-center">
    <div class ="passation-button-div d-flex justify-content-center" *ngIf="!hasPassation()">
      <button type="button" class="btn btn-primary" (click)="startPassation()" >Démarrer la passation</button>
    </div>

    <div class ="passation-button-div d-flex justify-content-center" *ngIf="hasPassation()">
      <button type="button" class="btn btn-primary" (click)="existingPassation()"  >Reprendre</button>
      <button type="button" class="btn btn-primary" (click)="startPassation()" >Nouvelle passation</button>
    </div>
  </div>
</div>


<router-outlet > </router-outlet>


<div class="d-flex justify-content-center align-items-center flex-column footer-wrapper  hidePrint">
  <div>Une réalisation du Keepic'Lab à Poitiers (86).</div>
  <div>Rendez-nous visite sur &nbsp; <a class="ui-login-link" href="https://www.keepic.net" target="_new">www.keepic.net</a> !</div>
</div>


