import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {Router} from '@angular/router';
import { PassationService} from '../passation.service';
import {UserService,UserDetails} from "../user.service";


@Component({
  selector: 'app-passation',
  templateUrl: './passation.component.html',
  styleUrls: ['./passation.component.css'],
  host: {'class': 'ui-body-content d-flex flex-grow-1 flex-column'}
})
export class PassationComponent implements OnInit {

  username:String;



  constructor(private router : Router,private passationService : PassationService , private userService: UserService) { }

  ngOnInit(): void {
    this.username = this.userService.getUserDetails().name;

  }

  hasPassation(){
      return this.passationService.hasPassation();
  }

  startPassation(){
        this.passationService.startNewPassation();
        let passation = this.passationService.getPassation();
        let userdetails:UserDetails = this.userService.getUserDetails();
        passation.passateurName = userdetails.name;
        passation.organizationName = userdetails.organizationName;
        this.router.navigateByUrl('/information');
  }

  existingPassation(){
      this.router.navigateByUrl('/information');
  }

  isOnRoot(){
      return this.router.url == "/";
  }

  isAdmin(){
      return this.userService.isAdmin();
  }
  goToAdmin(){
    this.router.navigateByUrl('/admin');
  }

  logout(){
   this.userService.logout().subscribe();
  }

}
