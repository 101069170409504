
<div class="d-flex align-items-center header-wrapper hidePrint">
  <div class="col-1"></div>
  <div><img src="../../assets/logo_atev.png" class="header-logo" /></div>
  <div class="d-flex flex-grow-1 justify-content-end align-items-center">
    <div class="header-item">{{username}}</div>
    <button class="  btn btn-primary btn-deconnexion header-item"  routerLink="/" >Passation</button>
    <button (click)="logout()" class="  btn btn-primary btn-deconnexion header-item">se déconnecter</button>
  </div>
  <div class="col-1"></div>
</div>
<div class="d-flex justify-content-center " style="padding : 1em;">
  <a *ngIf="isSuperAdmin==true" target="_blank" class="btn btn-primary btn-border" download="content.txt" href="{{exportUrl}}">Exporter les passations</a>
</div>

<div class=" d-flex justify-content-around  flex-column flex-md-row align-items-start ">
  <div  *ngIf="users != null"  class="d-flex  flex-column justify-content-around align-items-center ">
    <h3>Utilisateurs</h3>
    <div class="d-flex align-items-center">
      <label for="userFilter"> filtre :</label>
      <input id="userFilter" type="text" [(ngModel)]="userFilter">
      <label for="userShowDisabled">Voir les utilisateurs désactivés :</label>
      <input type="checkbox" id="userShowDisabled" [(ngModel)]="userShowDisabled">
    </div>
    <div  class="d-flex admin-table-row" *ngFor="let user of users|userText:userFilter |enabledOnly:!userShowDisabled">
      <div  [ngClass]="{'admin-disabled':!user.enabled}" class ="d-flex flex-grow-1 align-items-center" style="padding-right : 2rem;">
        {{user.name}} : {{user.organizationName}}
        <span class="ui-input-hint ">{{roleMap[user.roleId]}}</span>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <button class="btn btn-secondary btn-admin" [disabled]="user.roleId==2 && isSuperAdmin==false" (click)="openUpdateUserModal(updateUserModal,user)"> Modifier</button>
        <button class="btn btn-secondary btn-admin" [disabled]="user.roleId==2 && isSuperAdmin==false" (click)="openUpdatePasswordUserModal(updatePasswordUserModal,user)"> Changer mot de passe</button>
        <button *ngIf="user.enabled" class="btn btn-secondary btn-admin" [disabled]="user.roleId >=  userRole" (click)="disableUser(user.id,user.name)"> Désactiver</button>
        <button *ngIf="!user.enabled" class="btn btn-secondary btn-admin" [disabled]="user.roleId >=  userRole" (click)="enableUser(user.id,user.name)"> Activer</button>

      </div>
    </div>
    <button  class="btn btn-primary " (click)="openCreateUserModal(newUserModal)">Créer</button>
  </div>
  <div  *ngIf="isSuperAdmin==true && organizations != null " class="d-flex flex-column justify-content-around align-items-center">
    <h3>Organisations</h3>
    <div class="d-flex align-items-center">
      <label for="orgShowDisabled">Voir les Organisations désactivés :</label>
      <input type="checkbox" id="orgShowDisabled" [(ngModel)]="orgShowDisabled">
    </div>
    <div    class="d-flex admin-table-row" *ngFor="let organization of organizations | enabledOnly:!orgShowDisabled ">
      <div [ngClass]="{'admin-disabled':!organization.enabled}" class ="d-flex flex-grow-1 align-items-center" style="padding-right : 2rem;">
        {{organization.name}}
      </div>
      <div  class="d-flex justify-content-end align-items-center">
        <button class="btn btn-secondary btn-admin"  (click)="openUpdateOrgModal(updateOrganizationModal,organization)"> Modifier</button>
        <button *ngIf="organization.enabled" class="btn btn-secondary btn-admin"  (click)="disableOrganization(organization)"> Désactiver</button>
        <button *ngIf="!organization.enabled" class="btn btn-secondary btn-admin"  (click)="enableOrganization(organization)"> Activer</button>

      </div>

        </div>
    <div *ngIf="organizations.length ==0"> <i>Aucune organisation</i></div>
    <button class="btn btn-primary " (click)="openCreateOrgModal(newOrganizationModal)">Créer</button>
  </div>

</div>

<ng-template #newOrganizationModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title">Créer une organisation</h3>
        <form class="modal-form-div" ngNativeValidate (ngSubmit)="createOrganization(newOrgName)" method="POST">
          <label for="newOrgFormName">Nom de l'organisation</label>
          <input minlength="4" size="30" type="text"  [(ngModel)]="newOrgName" id="newOrgFormName" name="newOrgFormName"  required>
          <div *ngIf ="newOrgErrorText!='' " class="adminError"> Erreur : {{newOrgErrorText}}</div>
          <div class ="admin-modal-bottom-div">
            <button class ="btn btn-warning"  (click)="modalClose()"> Annuler</button>
            <input  type="submit" class ="btn btn-success" value="Valider" >
          </div>
        </form>


    </div>
  </div>
</ng-template>

<ng-template #updateOrganizationModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title">Modifier {{editedOrgName}}</h3>
      <form class="modal-form-div" ngNativeValidate (ngSubmit)="updateOrganization(updateOrgId,updateOrgName)">
        <label for="updateOrgName">Nom de l'organisation</label>
        <input required minlength="4" size="30" [(ngModel)]="updateOrgName" id="updateOrgName" name ="updateOrgName" type="text" >
        <div *ngIf ="updateOrgErrorText!='' " class="adminError"> Erreur : {{updateOrgErrorText}}</div>
      <div class ="admin-modal-bottom-div">
        <button class ="btn btn-warning"  (click)="modalClose()">Annuler</button>
        <input type="submit" class ="btn btn-success" value="Valider">
      </div>
      </form>
    </div>
  </div>
</ng-template>


<ng-template #newUserModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title"> Créer un nouvel utilisateur</h3>
      <form (ngSubmit)="createUser(newUser)" ngNativeValidate class ="modal-form-div">
        <label for="newUserName">Nom d'utilisateur</label>
        <input required minlength="4" size="30" [(ngModel)]="newUser.name" name="newUserName" id ="newUserName" type="text">
        <label type="email" for="newUserEmail">Email</label>
        <input required size= "30" [(ngModel)]="newUser.email" id="newUserEmail" name ="newUserEmail"type="email">
        <label for="newUserPassword">Mot de passe</label>
        <input required  [(ngModel)]="newUser.password" id ="newUserPassword" name ="newUserPassword" type="password">
        <label for="newUserPasswordConfirm">Confirmez le mot de passe</label>
        <input  [ngClass]="{'password-confirm-wrong':newUserPasswordConfirm != newUser.password}" required  [(ngModel)]="newUserPasswordConfirm" id ="newUserPasswordConfirm" name ="newUserPasswordConfirm" type="password">
        <div *ngIf="isSuperAdmin == true">
          <label  for="newUserOrg">Organisation</label>
          <select required [(ngModel)]="newUser.organizationId" name ="newUserOrg" id="newUserOrg">
            <option [ngValue]="organization.id"  *ngFor="let organization of organizations|enabledOnly:true">{{organization.name}}</option>
          </select>
        </div>
        <div *ngIf="isSuperAdmin == true">
          <label for="newUserRole">Role</label>
          <select [(ngModel)]="newUser.roleId" name="newUserRole" id="newUserRole">
            <option [ngValue]="0">Passateur</option>
            <option [ngValue]="1">Administrateur</option>
          </select>
        </div>
        <div *ngIf ="createUserErrorText!='' " class="adminError"> Erreur : {{createUserErrorText}}</div>
        <div class ="admin-modal-bottom-div">
          <button class ="btn btn-warning"  (click)=" modalClose()" > Annuler </button>
          <input [disabled]="newUserPasswordConfirm != newUser.password " type="submit" class ="btn btn-success"  value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #updateUserModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title"> Modifier {{editedUserName}}</h3>
      <form  (ngSubmit)="updateUser(toUpdateUser)" ngNativeValidate class="modal-form-div" >
        <label for="updateUserName">Nom d'utilisateur</label>
        <input required minlength="4" size="30" [(ngModel)]="toUpdateUser.name" name="updateUserName" id="updateUserName" type="text">
        <label for="updateUserEMail">Email</label>
        <input required  size= "30" [(ngModel)]="toUpdateUser.email" name ="updateUserEMail" id="updateUserEMail" type="email">
        <div *ngIf="isSuperAdmin ==true">
          <label for="updateUserOrg">Organisation</label>
          <select required  [(ngModel)]="toUpdateUser.organizationId" name ="updateUserOrg" id="updateUserOrg">
            <option [ngValue]="organization.id"  *ngFor="let organization of organizations|enabledOnly:true">{{organization.name}}</option>
          </select>
        </div>
        <div *ngIf="toUpdateUser.roleId ==2 ">
          <label for="updateUserRoleSA" >Role</label>
          <select disabled="true" id ="updateUserRoleSA">
            <option selected ="true">Super Administrateur</option>
          </select>
        </div>
        <div *ngIf="toUpdateUser.roleId !=2 && isSuperAdmin ==true">

          <label for="updateUserRole">Role</label>
          <select [(ngModel)]="toUpdateUser.roleId" name="updateUserRole" id="updateUserRole">
            <option [ngValue]="0">Passateur</option>
            <option [ngValue]="1">Administrateur</option>
          </select>
        </div>
        <div *ngIf ="updateUserErrorText!='' " class="adminError"> Erreur : {{updateUserErrorText}}</div>
        <div class ="admin-modal-bottom-div">
          <button class ="btn btn-warning"  (click)="modalClose()" > Annuler </button>
          <input type="submit" class ="btn btn-success" value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>


<ng-template #updatePasswordUserModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container modal-container-div">
      <h3 class ="admin-modal-title"> Changer le mot de passe de {{editedUserName}}</h3>
      <form (ngSubmit)="updateUserPassword(updatePwdUserId,updatePwd)" ngNativeValidate class="modal-form-div">
        <label for="updatePwd">Nouveau mot de passe</label>
        <input required minlength="8"  [(ngModel)]="updatePwd" id ="updatePwd" name ="updatePwd" type="password">
        <label for="updatePasswordConfirm">Confirmez le nouveau mot de passe</label>
        <input  [ngClass]="{'password-confirm-wrong':updatePasswordConfirm != updatePwd}" required
                [(ngModel)]="updatePasswordConfirm" id ="updatePasswordConfirm" name ="updatePasswordConfirm" type="password">
        <div *ngIf ="updatePwdErrorText!='' " class="adminError"> Erreur : {{updatePwdErrorText}}</div>
        <div class ="admin-modal-bottom-div">
          <button class ="btn btn-warning"  (click)=" modalClose()" > Annuler </button>
          <input type="submit" [disabled]="updatePwd != updatePasswordConfirm " class ="btn btn-success" value="Valider">
        </div>
      </form>
    </div>
  </div>
</ng-template>
