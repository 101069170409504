<div class="container d-flex flex-grow-1 align-items-center" *ngIf="passation">

    <div  class="passation-main-div container">
      <h4 style="text-align: center;color:#81b3a3;">Chaque élément que vous avez choisi peut s'inscrire dans l'un des 4 piliers de la qualité de vie</h4>
      <h2 class="" class="passation-title-div">Inscrivez chacun des éléments cités dans le pilier correspondant</h2>
      <div  *ngFor="let item of passation.items ; let i = index" class="">
        <div *ngIf="item.name!==''" class = "row d-flex pillar-item-div"  >
          <div class="d-flex align-items-center pillar-item-in-list"  [class.item-pillar-chosen]="item.pillar!==null">
            <div class = "pillar-item-detail-div d-flex flex-grow-1">
              <div class=" item-number-div item-color-{{i+1}} col-2">{{i+1}}</div>
              <div class="passation-item-name-div flex-grow-1" >{{item.name}} </div>
            </div>
            <div  class ="pillar-mini-div"><img *ngIf="item.pillar !== null" src="../../assets/pillar-{{item.pillar}}.png"></div>
            <div><button  class="btn btn-secondary" (click)="openPillarModal(pillarModal,i)">Choisir</button></div>
          </div>
        </div>
      </div>
    </div>

</div>
<div class="passation-button-div d-flex justify-content-center">
  <button class="btn btn-primary btn-danger" routerLink="/items-note">retour</button>
  <button [disabled]="!validateStep()" class="btn btn-primary btn-success" routerLink="/results">synthèse</button>
</div>

<ng-template #pillarModal let-modal>
  <div class="d-flex flex-grow-1">
    <div class="container">
        <div class="row">
          <div class="col-12 col-md-6  pillar-item-area">
              <div class="pillar-item d-flex flex-column" (click)="choosePillar(modal,0)" style="border-color:#81b3a3;">
                <div class="pillar-title d-flex">
                  <h2 class="d-flex flex-grow-1">Sécurité et cadre de vie</h2>
                  <div class="pillar-image"><img src="../../assets/pillar-0.png" /></div>
                </div>
                <ul class="pillar-description">
                  <li>Votre sécurité face aux aléas de la vie : emploi, revenus, logement, situation administrative, sentiment d’être protégé…</li>
                  <li>Votre environnement : beauté et confort du cadre de vie, météo, qualité de l’environnement, accès aux services…</li>
                </ul>
              </div>
          </div>
          <div class="col-12 col-md-6 pillar-item-area">
            <div class="pillar-item d-flex flex-column" (click)="choosePillar(modal,1)" style="border-color:#e4526d;">
              <div class="pillar-title d-flex">
                <h2 class="d-flex flex-grow-1">Santé et autonomie</h2>
                <div class="pillar-image"><img src="../../assets/pillar-1.png" /></div>
              </div>
              <ul class="pillar-description">
                <li>Votre santé physique et mentale : Avoir une bonne santé, ne pas être malade, bien gérer sa maladie, guérir, agir en prévention : alimentation saine, activité physique, sommeil…</li>
                <li>Votre autonomie : pouvoir vous déplacer, faire ce que vous avez à faire, ne pas dépendre des autres…</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 pillar-item-area">
            <div class="pillar-item d-flex flex-column" (click)="choosePillar(modal,2)" style="border-color:#f8cf63;">
              <div class="pillar-title d-flex">
                <h2 class="d-flex flex-grow-1">Vie affective et relation aux autres</h2>
                <div class="pillar-image"><img src="../../assets/pillar-2.png" /></div>
              </div>
              <ul class="pillar-description">
                <li>Votre vie affective : conjoint-e, enfants, parents, amis proches, santé des proches, bonheur et réussite des proches, animal de compagnie…</li>
                <li>Votre vie sociale : collègues de travail, voisins, relations sociales…</li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-6 pillar-item-area">
            <div class="pillar-item d-flex flex-column" (click)="choosePillar(modal,3)" style="border-color:#c8d538;">
              <div class="pillar-title d-flex">
                <h2 class="d-flex flex-grow-1">Ressources personnelles et loisirs</h2>
                <div class="pillar-image"><img src="../../assets/pillar-3.png" /></div>
              </div>
              <ul class="pillar-description">
                <li>Vos qualités propres et votre rapport à la vie : avoir confiance en soi, se sentir en capacité de faire des choix, d’être libre, être en accord avec soi-même, qualité de la vie spirituelle, religion…</li>
                <li>Vos loisirs : activités sportives, de loisirs, artistiques, culturelles, voyages, vacances, bricolage…</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  </div>
</ng-template>

