import { Injectable,OnInit } from '@angular/core';
import { Observable, of,Subject } from 'rxjs';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import  {serverURL} from "./config";
import { map } from 'rxjs/operators'
import {Router} from '@angular/router';
import {PassationService} from './passation.service';

@Injectable({
  providedIn: 'root'
})
export class UserService   {

  private loginUrl :string = serverURL+"/login";
  private logoutUrl :string = serverURL+"/logout";
  private userDetails?:UserDetails;
  private isInitUser:boolean = false;
  public  roleMap:String[] = ["Passateur","Admin","SuperAdmin"];

  constructor(private passationService: PassationService, private router : Router,private http: HttpClient) { }

  login(identifiant:string, password:string):Observable<UserDetails> {
    let body = new FormData();
    body.append('username', identifiant);
    body.append('password', password);
    var res:Subject<UserDetails> = new Subject();
    this.http.post(this.loginUrl,body ,{withCredentials: true } )
      .subscribe(value=>{
        this.http.get<UserDetails>(serverURL+"/api/user",{withCredentials: true }).subscribe(value=>{
          res.next(value);
          this.userDetails = value;
          localStorage.setItem('user',JSON.stringify(value));
        },
        err=>{
          res.error(err);
        }
        );

    },err=>{
       res.error(err);
     });
    return res;
  }

  logout():Observable<object>{
         let res = this.http.post<object>(this.logoutUrl,{},{withCredentials: true } );
         res.subscribe(value=>{
                      this.onLogout();
                     });
         return res;
  }

  onLogout(){
      localStorage.setItem('user',null);
      this.router.navigateByUrl('/login');
      this.passationService.clearPassation();
  }

  getUserDetails():UserDetails{
    if (!this.isInitUser){
      this.isInitUser = true;
      let userString:string = localStorage.getItem('user');
      if (userString != null){
        this.userDetails = JSON.parse(userString);

      }
    }
    return this.userDetails;
  }

  isAdmin():boolean{
    if (this.userDetails == null)
      return false;
    return (this.userDetails.roleId == 1 || this.userDetails.roleId == 2);
  }
}

export class UserDetails{
  id: Number;
  email:String;
  name:String;
  roleId:Number;
  organizationId:Number;
  organizationName:String;
  enabled : boolean;
}

export class UserRequest{
    id :Number;
    name:String;
    email:String;
    roleId:Number;
    organizationId:Number;
    organizationName:String;
    password:String;
}


