import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild,Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PassationService} from './passation.service';
import { UserService} from './user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassationChildsGuard implements CanActivateChild {
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.passationService.hasPassation()){
       return this.router.parseUrl('/');
    }
    else
      return true;
  }
  constructor(private passationService : PassationService, private router: Router,
              private userService : UserService) { }
}
