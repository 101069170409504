import { Pipe, PipeTransform } from '@angular/core';
import {UserDetails} from './user.service';
@Pipe({
  name: 'userText'
})
export class UserTextPipe implements PipeTransform {

  transform(user:UserDetails[] , filter:string): UserDetails[] {
    if(filter == "")
      return user;

    else return user.filter(user=> user.name.indexOf(filter)  !== -1 || user.organizationName.indexOf(filter) !==-1)
  }

}
