<div class="d-flex flex-grow-1 align-items-center justify-content-center">
  <div class="" *ngIf="passation">
    <div class="container passation-main-div">
      <h2 class="passation-title-div">Comment ça va aujourd'hui pour chaque élément ? Attribuez une note de 0 à 10 </h2>
      <div class="">0 = ça ne va pas du tout, 10 = ça va très bien</div>
        <div  *ngFor="let item of passation.items ; let i = index" >
          <div *ngIf="item.name!==''" class = "row d-flex "  >
            <div class =" passation-item-div flex-grow-1 justify-content-start border-item-color-{{i+1}} ui-item-list-item">
              <div class=" item-number-div item-color-{{i+1}} col-2">{{i+1}}</div>
              <div class="passation-item-name-div flex-grow-1" >{{item.name}}</div>
              <div class="d-flex">
                 <div class="d-flex flex-column">
                   <!--<input disabled="true" class="passation-range-div passation-range-div-disabled" [(ngModel)]="item.weight" type="range" min="0" max="100" >-->
                  <div class="passation-item-weight-text"> {{item.weight}}% </div>
                </div>
              </div>
              <div class="passation-note-div flex-grow-1">
                <select (change)="savePassation()" [(ngModel)]="item.note" class="ui-select-highlight">
                  <option hidden [ngValue] ="-1"  >-</option>
                  <option [ngValue] ="note" *ngFor="let note of notations" >{{note}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
<div class="passation-button-div d-flex justify-content-center">
  <button class="btn btn-primary" routerLink="/items-weight">retour</button>
  <button [disabled] = "!validateStep()" class="btn btn-primary"  routerLink="/items-pillar">suivant</button>
</div>
