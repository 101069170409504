import { Component } from '@angular/core';
import {UserService} from './user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: {'class': 'ui-body d-flex flex-grow-1 flex-column'}
})
export class AppComponent {
  title = 'Eval Etu';
}
