import { Component, OnInit } from '@angular/core';
import {Passation, Pillar} from '../passation';
import {PassationService} from '../passation.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-items-pillar',
  templateUrl: './items-pillar.component.html',
  styleUrls: ['./items-pillar.component.css'],
  host: {'class': 'ui-body-content-component d-flex flex-grow-1 flex-column'}
})
export class ItemsPillarComponent implements OnInit {

  constructor(private passationService : PassationService
             ,private modalService: NgbModal) { }

  passation:Passation;
  itemEdited ?:string;

  ngOnInit(): void {
        this.passation = this.passationService.getPassation();
  }

    openPillarModal(pillarModal,itemEdited) {
      this.itemEdited =itemEdited;
      this.modalService.open( pillarModal,{ariaLabelledBy: 'modal-basic-title',size: 'xl'});
    }

    choosePillar(modal, pillarIndex:string){
      this.passation.items[this.itemEdited].pillar = pillarIndex;
      this.passationService.savePassation();
      modal.close();
    }

  validateStep(){
    for (let itemId in this.passation.items){
      if (this.passation.items[itemId].name !== "" && this.passation.items[itemId].pillar == null)
        return false;
    }
    return true;
  }

  savePassation():void{
    this.passationService.savePassation();
  }
}
