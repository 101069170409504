import { Component, OnInit,AfterViewChecked } from '@angular/core';
import {Passation, Pillar,Item,GenderEnum  } from '../passation';
import {PassationService} from '../passation.service';
import * as d3 from 'd3';
import * as Chartist from 'Chartist';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {UserService} from '../user.service';


@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css'],
  host: {'class': 'ui-body-content-component d-flex flex-grow-1 flex-column'}
})
export class ResultsComponent implements OnInit,AfterViewChecked {

  constructor(private userService:UserService,private router:Router,private passationService : PassationService, private modalService: NgbModal) { }

  private svg;
  private margin = 0;
  private width = 750;
  private height = 600;
  // The radius of the pie chart is half the smallest side
  private radius = Math.min(this.width, this.height) / 2 - this.margin;
  private colors;
  private activeModal;
  commentControlForm = new FormControl();


  pillarEnum = Pillar;
  passation:Passation;
  previousPassationList ?: Passation[];
  hasPreviousPassation?: boolean;
  previousPassation:Passation;
  previousPassationChoice ?:Passation = null;
  activeTab:number = -1;
  buildPreviousPassationGraph:boolean= false;
  pillarScore : Map<Pillar,number>;
  isPillarPresent:boolean[] = [false,false,false,false];
  pillarEvolution;
  test:String;
  username:String;
  organization:String;
  sendError:String;

  ngOnInit(): void {
        this.passation = this.passationService.getPassation();
        this.passation.totalScore = 0;
        this.passation.items.forEach(item=>{
          if(item.name !== ''){
            item.score = item.weight*item.note / 10/*0*/;
            this.passation.totalScore += + item.score;
          }
        });
        //this.computePies(this.passation,"current-passation-pies");
        this.createD3PieCharts(this.passation,"current-passation-pies");
        this.passationService.getPreviousPassations().subscribe(anwser=> {
            this.previousPassationList = anwser.passations;
            this.hasPreviousPassation = this.previousPassationList.length > 0;
        });
  }

  ngAfterViewChecked():void{
    if (this.buildPreviousPassationGraph){
          //this.computePies(this.previousPassation,"previous-passation-pies");
          this.createD3PieCharts(this.previousPassation,"previous-passation-pies");
          this.buildPreviousPassationGraph = false;

    }
  }

  public toGenderString(genderId:number){
    return GenderEnum[genderId];
  }


  onSendDone(){
    this.passationService.clearPassation();
    this.router.navigateByUrl('/');
  }

  openModal(modal):void{
    this.modalService.open(modal);
  }



  onValidatePassationChoice(modal,chosenPassation:Passation):void{
    modal.close();
    this.previousPassation = chosenPassation;
    this.buildPreviousPassationGraph = true;
    this.activeTab =1;
    this.computePillarEvolution();
  }

  onValidateSave(modal){
   this.passationService.sendPassation().subscribe(
    value=>{
      this.onSendDone();
      modal.close();
    },error=>{
      if (error == 0){
        this.sendError = "Le serveur ne répond pas.";
      }
      else{
        this.sendError ="Erreur : code "+ error;
      }
    }
   );
  }

  onPrintClicked():void{
    window.print();
  }

  cancelPassationComparaison(){
    this.activeTab = -1;
    this.previousPassation = null;
  }

  createD3PieCharts(passation:Passation,divId:String){
      let pillarProportionData = [0,0,0,0];
          let pillarScoreData = [0,0,0,0,0];
          let pillarProportionNames = [];
          let pillarScoreNames  = [];
          passation.items.forEach(item=>{
              if(item.name != ""){
                  pillarProportionData[item.pillar] += item.weight;
                  pillarScoreData[item.pillar] += item.score;
              }

          });
          for (let id =0;id<4; id++){
            if (pillarProportionData[id] >0){
              this.isPillarPresent[id] = true;
            }
            if ( pillarProportionData[id] <3){
              pillarProportionNames.push(" ");
            }
            else{
              pillarProportionNames.push(pillarProportionData[id].toFixed(1)+"%");
            }
            if (pillarScoreData[id] <3 ){
              pillarScoreNames.push(" ");
            }
            else{
            pillarScoreNames.push(pillarScoreData[id].toFixed(1)+"");
            }
          }
          if (passation.totalScore <100){
            let reste = 100-passation.totalScore;
            pillarScoreData[4] = reste;
            pillarScoreNames.push("");
          }
        let colors = ["#81b3a3","#e4526d" ,"#f8cf63","#c8d538", "#eaeaea"];
        let width = 250;//element.getBoundingClientRect().width;
        let height = 250;//element.getBoundingClientRect().height;
        let radius = Math.min( height,width)/2*0.9;


        let svg = d3.select("#"+divId+" .pillarProportion")
          .append("svg")
          .attr("preserveAspectRatio", "xMinYMin meet")
          .attr("viewBox", "0 0 "+width+" "+height)
          .classed("svg-content", true);

        let pieGen = d3.pie<Number>().sort(null);//.value( item => item.weight);
        let g = svg.append("g").attr("transform", "translate("+width/2+","+ height/2 +")");
        var arcGen  = d3.arc()
                      .innerRadius(0)
                      .outerRadius(radius);
        let arcsData = pieGen(pillarProportionData);
        let arcLabel = <any>d3.arc().innerRadius(0).outerRadius(radius);

        let testArc = g.attr("stroke", "white")
        .selectAll("path")
        .data(arcsData)
              .join("path")
              .attr("fill", (d,i) => colors[i])
              .attr("d", <any>arcGen)
         ;

         svg.append("g").attr("transform", "translate("+width/2+","+ height/2 +")")
         .attr("font-family", "sans-serif")
         .attr("font-size", "large")
         .attr("text-anchor", "middle")
         .selectAll("text").data(arcsData)
          .join("text")
          .text((d,i)=> pillarProportionData[i] >2? pillarProportionNames[i]:"")
          .attr("transform",(d)=>   'translate('+arcLabel.centroid(d)+')')
          ;

          // score graph
          svg = d3.select("#"+divId+" .pillarScoreProportion")
            .append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", "0 0 "+width+" "+height)
            .classed("svg-content", true);

           pieGen = d3.pie<Number>().sort(null);//.value( item => item.weight);
           g = svg.append("g").attr("transform", "translate("+width/2+","+ height/2 +")");
           arcGen  = d3.arc()
                        .innerRadius(0)
                        .outerRadius(radius);
           arcsData = pieGen(pillarScoreData);
           arcLabel = <any>d3.arc().innerRadius(0).outerRadius(radius);

           testArc = g.attr("stroke", "white")
          .selectAll("path")
          .data(arcsData)
                .join("path")
                .attr("fill", (d,i) => colors[i])
                .attr("d", <any>arcGen)
           ;

           svg.append("g").attr("transform", "translate("+width/2+","+ height/2 +")")
           .attr("font-family", "sans-serif")
           .attr("font-size", "large")
           .attr("text-anchor", "middle")
           .selectAll("text").data(arcsData)
            .join("text")
            .text((d,i)=> pillarScoreData[i] >2? pillarScoreNames[i]:"")
            .attr("transform",(d)=>   'translate('+arcLabel.centroid(d)+')')
            ;
      /*   testArc.append("text")
                        .attr("transform", function(d) {
                                 return "translate(" + arcLabel.centroid(d) + ")";
                         })
                        .call(d => console.log(d));
        */
        /*svg.append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 12)
            .attr("text-anchor", "middle")
          .selectAll("text")
          .data(arcsData)
          .join("text")
          .attr("transform",d=> "translate("+ arcLabel.centroid(d) + ")")
          .call(text => text.append("tspan")
              .attr("y", "-0.4em")
              .attr("font-weight", "bold")
              .text(d => d.data.name))
          /* .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append("tspan")
                    .attr("x", 0)
                    .attr("y", "0.7em")
                    .attr("fill-opacity", 0.7)
                    .text(d => d.data.score.toLocaleString()));*/
  }

  computePillarEvolution(){
    this.pillarEvolution = [4];
    for (let i=0; i<4;i++){
      this.pillarEvolution[i] = [0,0];
    }
    this.passation.items.forEach(item=>{
        if(item.name != ""){
          this.pillarEvolution[item.pillar][1] += item.score;
        }
    });
    this.previousPassation.items.forEach(item=>{
        if(item.name != ""){
          this.pillarEvolution[item.pillar][0] += item.score;
        }
    });
  }
}
